import api from "./http"

// 获取目录列表
export const getMenuData = () =>
  api({
    url: `/ram/product/product_menu/get/`,
    method: "get"
  })
export const getProductData = (index) =>
  api({
    url: `/ram/product/product_content/get/?index=${index}`,
    method: "get"
  })
export const getProductNav = () =>
  api({
    url: `/ram/product/second_menu/get/`,
    method: "get"
  })
export const submitMessage = (data) =>
  api({
    url: `/ram/product/submit_message/`,
    method: "post",
    data
  })
      
export const getSubMenuCont = (sub_menu_index) =>
  api({
    url: `/ram/product/sub_menu_content/?sub_menu_index=${sub_menu_index}`,
    method: "get"
  })

export const addVisitTime = () =>
  api({
    url: `/ram/product/add_visit_times/`,
    method: "get"
  })
export const getTechPlatform = () =>
  api({
    url: `/ram/product/get_platform/`,
    method: "get"
  })
export const getServiceCenter = () =>
  api({
    url: `/ram/product/get_service/`,
    method: "get"
  })
export const getTechPlatformContent = (id) =>
  api({
    url: `/ram/product/get_platform_detail/`,
    method: "get",
    params: {
      id
    }
  })
export const getServiceCenterContent = (id) =>
  api({
    url: `/ram/product/get_service_detail/`,
    method: "get",
    params: {
      id
    }
  })